import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContainer } from 'entities/Authentication';
import { LanguageSwitch } from 'features/SwitchLanguage';
import { PlatformToken, useOtpExchange, useSendOtp } from "shared/api/otpApi"
import { Flex, MaterialInput, Button, Typography, Envelope } from 'shared/_ui';
import styles from './styles.module.scss';
//import { getRouteReset } from 'shared/consts';
import { notify } from 'shared/lib/notification';
import { useForm } from 'react-hook-form';
import i18next from 'i18next';
import { getRouteMain } from 'shared/consts';
import { useUserActions } from 'entities/User';

interface TypingOtp {
  t1: string;
  t2: string;
  t3: string;
  t4: string;
}

export const OtpCheckPage: React.FC = () => {
  const { t } = useTranslation();
  const [ exchange, { isLoading } ] = useOtpExchange()
  const email = localStorage.getItem('user-email')
  const [ otplogin ] = useSendOtp();
  const { setAuthData } = useUserActions();
  function setFocusTo(id: string) {
    return ()=>{document.getElementById(id)!.focus()}
  }
  const {
    register,
    handleSubmit,
  } = useForm<TypingOtp>({
    mode: 'onSubmit',
    defaultValues: { t1: "", t2: "", t3: "", t4: "" }
  });

  const onSubmit = async (v: TypingOtp) => {
    const otpV = v.t1 + v.t2 + v.t3 + v.t4;
    await exchange({email: email ?? "", otp: Number(otpV)})
      .unwrap()
      .then((res : PlatformToken) => {
        setAuthData(res);
        window.location.replace(getRouteMain());
      })
      .catch(() => {
        notify.error(t('authentication.otp.wrong'));
      });
  };

  const onResend = async () => {
    if(localStorage.getItem('resend-time') == null) {
      localStorage.setItem('resend-time', new Date().getTime().toString())
    }
    if( Number(localStorage.getItem('resend-time')) > new Date().getTime() ) {
      notify.error(`${t('authentication.otp.wait')}${Math.ceil(( Number(localStorage.getItem('resend-time')) - new Date().getTime() ) / 1000)}${t('authentication.otp.seconds')}`);
      return
    }

    await otplogin({email: email ?? "", languageCode: i18next.language})
      .unwrap()
      .then(() => {
        localStorage.setItem( 'resend-time', (new Date().getTime() + 60000).toString() )
        notify.success(t('authentication.otp.resendSuccess'))
      })
      .catch(() => {
        notify.error(t('authentication.login.error'));
      });
  }

  return (
    <AuthenticationContainer
      layoutControls={<LanguageSwitch />}
      title={t('authentication.otp.title')}
      hint={<Flex direction='row' spacing={5} className={styles.wrapper}>
              <Typography.Text type='secondary'>
                {t("authentication.otp.nocode")}
              </Typography.Text>
              <Typography.Link type="primary" onClick={onResend}>
                {t("authentication.otp.resend")}
              </Typography.Link>
            </Flex>}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex spacing={20} direction='column' className={styles.wrapper}>
          <Flex justify='space-between' spacing={10} direction='row' className={styles.wrapper}>
            <MaterialInput className={styles.input} onInput={setFocusTo("t2")} type="tel" maxLength={1} {...register('t1')}></MaterialInput>
            <MaterialInput className={styles.input} id="t2" onInput={setFocusTo("t3")} {...register('t2')} type="tel" maxLength={1}></MaterialInput>
            <MaterialInput className={styles.input} id="t3" onInput={setFocusTo("t4")} {...register('t3')} type="tel" maxLength={1}></MaterialInput>
            <MaterialInput className={styles.input} id="t4" {...register('t4')} type="tel" maxLength={1}></MaterialInput>
          </Flex>

          <Typography.Text >{t('authentication.otp.code')}</Typography.Text>
          
          <Typography.Mark className={styles.mail}>
            <Envelope className={styles.envelope} />
            <Typography.Text strong type="default" size={2}>
              {email}
            </Typography.Text>
          </Typography.Mark>

          <Button type="primary" loading={isLoading}>
                {t('authentication.otp.next')}
          </Button>
        </Flex>
      </form>
    </AuthenticationContainer>)
};
