export enum AppRoutes {
  MAIN = 'main',
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  REGISTRATION = 'registration',
  RESET = 'pw_reset',
  RESTORE = 'restore',
  RESTORE_SUCCESS = 'restore_success',
  CHANGE_PASSWORD = 'change_password',
  EMAIL_VERIFICATION = 'email_verification',
  SUCCESSFUL_PAYMENT = 'successful_payment',
  OTP_CHECK = 'otp_check',
  TEST = "test",

  // last
  NOT_FOUND = 'not_found',
}

export const getRouteMain = () => `/`;
export const getRouteConnections = () => `/connections`;
export const getRoutePayment = () => `/payment`;
export const getRouteSupport = () => `/support`;
export const getRouteLogin = () => `/login`;
export const getRestore = () => `/restore`;
export const getFaq = () => `/faq`;
export const getRestoreSuccess = () => `/restore-success`;
export const getRouteRegistration = (token: string) => `/registration/${token}`;
export const getRouteChangePassword = () => `/change-password`;
export const getSignUp = () => `/sign-up`;
export const getProfile = () => `/profile`;
export const getRouteReset = (token: string) => `/pw_reset/${token}`;
export const getRouteEmailVerification = () => `/email-verification`;
export const getRouteSuccessfulPayment = () => `/successful-payment`;
export const getRouteOtpCheck = () => `/otpcheck`
export const getRouteTest = () => undefined//`/test`
