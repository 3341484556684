import { Flex, Layout } from 'shared/_ui';
import { Header } from 'widgets/Header';
import { Footer } from 'widgets/Footer';
import { AvailableTariffs, CurrentTariff } from 'widgets/Tariff';
import { PromoCode } from 'widgets/PromoCode';
import { PromoCodeModal } from 'widgets/PromoCodeModal';
import { GuideSlider } from 'widgets/GuideSlider';
import { CONTROL_IDS } from 'shared/consts';
import { useStoreUiControls } from 'shared/lib';
import { ConnectionList } from 'widgets/ConnectionList';
import {
  PremiumExpirationWarningNineDays,
  PremiumExpirationWarningOneDay,
  PremiumExpirationWarningThreeDays,
  PremiumExpiredWarning,
  SevenDaysExpiredWarning,
} from 'entities/Banner';
import { HowItWorksModal } from 'widgets/HowItWorksModal';
import { ReferralLinkModal } from 'widgets/ReferralLinkModal';

const MainPage: React.FC = () => {
  const { isOpen: isGuideOpen } = useStoreUiControls(CONTROL_IDS.GUIDE);

  return (
    <>
      <Layout>
        <Layout.Header>
          <Header />
        </Layout.Header>
        <Layout.Content>
          <Flex direction="column" spacing={130}>
            {isGuideOpen ? <GuideSlider /> : <ConnectionList />}
            <Flex direction="column" spacing={64}>
              <CurrentTariff />
              <AvailableTariffs />
            </Flex>
            <PromoCode />
          </Flex>
        </Layout.Content>
        <Layout.Footer>
          <Footer />
        </Layout.Footer>
      </Layout>
      <SevenDaysExpiredWarning />
      <PromoCodeModal />
      <HowItWorksModal />
      <ReferralLinkModal />
      <PremiumExpirationWarningOneDay />
      <PremiumExpirationWarningThreeDays />
      <PremiumExpirationWarningNineDays />
      <PremiumExpiredWarning />
    </>
  );
};

export { MainPage };
